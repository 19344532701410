import ReactDOM from "react-dom";

const Modal = ({ open, children }) =>
  !open
    ? null
    : ReactDOM.createPortal(
        <div className="fixed inset-0 z-50">
          <div className="absolute inset-0 bg-black/50 " />
          <div className="absolute inset-0 z-20 flex flex-col justify-center px-4">{children}</div>
        </div>,
        document.getElementById("modal-root")
      );

export default Modal;
