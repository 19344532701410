import React from "react";
import { XCircleIcon } from "@heroicons/react/outline";
import Modal from "./Modal";
import screenshot_01 from "../assets/screenshots/screenshot-01.png";
import screenshot_02 from "../assets/screenshots/screenshot-02.png";
import screenshot_03 from "../assets/screenshots/screenshot-03.png";
import screenshot_04 from "../assets/screenshots/screenshot-04.png";

const ScreenShotsModal = ({ open, onClose }) => {
  React.useEffect(() => {
    document.title = "HARE - App screenshots";
    return () => {
      document.title = "HARE - We listen & fix IT";
    };
  }, []);
  return (
    <Modal open={open}>
      <div className="max-w-screen-lg max-h-[95vh] p-4 md:p-10 mx-auto bg-white rounded overflow-y-auto text-right">
        <button type="button" className="mb-8 md:mb-16 btn primary" onClick={onClose}>
          <XCircleIcon className="w-5 h-5 text-white" />
        </button>
        {/* Screenshots */}
        <div>
          <img
            src={screenshot_01}
            className="mt-2 overflow-hidden border rounded border-sky-400"
            alt="screenshot_01"
          />
          <img
            src={screenshot_02}
            className="mt-2 overflow-hidden border rounded border-sky-400"
            alt="screenshot_02"
          />
          <img
            src={screenshot_03}
            className="mt-2 overflow-hidden border rounded border-sky-400"
            alt="screenshot_03"
          />
          <img
            src={screenshot_04}
            className="mt-2 overflow-hidden border rounded border-sky-400"
            alt="screenshot_04"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ScreenShotsModal;
