import React, { useState } from "react";
import { ArrowDownTrayIcon, EyeIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { Form, useActionData, useLoaderData } from "react-router-dom";

const Analytics = () => {
  const loaderData = useLoaderData();
  const actionData = useActionData();

  return (
    <div className="min-h-screen  bg-[#1A222E] py-8 flex flex-col justify-center px-2">
      <div className="w-full max-w-md mx-auto">
        {/* <h1 className="p-4 text-orange-400">Hare Analytics</h1>
        <br /> */}
        {actionData && (
          <div className="flex justify-center">
            <div className="flex items-center px-4 mx-auto mb-6 space-x-4 text-sm font-medium text-center text-white bg-cyan-600/90 rounded-xl">
              <span>{actionData.startDate}</span>
              <span>-</span>
              <span>{actionData.endDate}</span>
            </div>
          </div>
        )}
        <div className="flex flex-col items-center p-6 space-y-8 border sm:flex-row sm:justify-around sm:space-x-8 sm:space-y-0 rounded-xl border-white/5">
          {/* Visits */}
          <div>
            <div className="opacity-80">
              <EyeIcon className="inline-block mr-2 text-orange-400 align-top w-7 h-7" />
              <span className="text-xl font-semibold text-white capitalize">
                Site Traffic
              </span>
            </div>
            <p className="mt-2 text-4xl font-bold text-center text-white ">
              {actionData?.visits ?? loaderData.visits}
            </p>
          </div>
          {/* Downloads */}
          <div>
            <div className="opacity-80">
              <ArrowDownTrayIcon className="inline-block mr-2 text-orange-400 align-top w-7 h-7" />
              <span className="text-xl font-semibold text-white capitalize">
                downloads
              </span>
            </div>
            <p className="mt-2 text-4xl font-bold text-center text-white ">
              {actionData?.downloads ?? loaderData.downloads}
            </p>
          </div>
        </div>
        {/* Filters */}
        <Form method="post">
          <div className="p-6 mt-8 border rounded-xl border-white/5">
            {/* Controles */}
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <label
                  htmlFor="startDate"
                  className="mb-3 text-xs font-bold uppercase text-white/90"
                >
                  Start Date
                </label>
                <input
                  type={"datetime-local"}
                  id="startDate"
                  className="block w-full px-4 py-2 text-white rounded bg-white/5 focus:outline-none focus:border-transparent placeholder:text-white/40"
                  name="startDate"
                  placeholder="Start Date"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="endDate"
                  className="mb-3 text-xs font-bold uppercase text-white/90"
                >
                  End Date
                </label>
                <input
                  type={"datetime-local"}
                  id="endDate"
                  className="block w-full px-4 py-2 text-white rounded bg-white/5 focus:outline-none focus:border-transparent placeholder:text-white/70"
                  name="endDate"
                  placeholder="End Date"
                  required
                />
              </div>
            </div>
            {/* button */}
            <br />
            <button type={"submit"} className="block w-full btn primary">
              Search
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Analytics;

export async function analyticsLoader() {
  const url = "http://localhost:3000/api/customer/hare/";
  // const url =
  //   "https://hamjambo-online-store.vercel.app/api/customer/hare/";
  const vres = await axios.get(url + "visit");
  const visits = vres.data;
  const dres = await axios.get(url + "download");
  const downloads = dres.data;

  return { visits, downloads };
}

export async function analyticsAction({ request }) {
  const data = Object.fromEntries(await request.formData());
  const startDate = data.startDate;
  const endDate = data.endDate;

  const url = "http://localhost:3000/api/customer/hare/";
  // const url =
  //   "https://hamjambo-online-store.vercel.app/api/customer/hare/";
  const vres = await axios.get(
    url + `visit?startDate=${startDate}&endDate=${endDate}`
  );
  const visits = vres.data;
  const dres = await axios.get(
    url + `download?startDate=${startDate}&endDate=${endDate}`
  );
  const downloads = dres.data;

  return { visits, downloads, startDate, endDate };
}
