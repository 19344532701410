// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC7IWCxp1tPbNyy8kTBx7MNsqYs64X8Xjk",
  authDomain: "hare-bd25b.firebaseapp.com",
  projectId: "hare-bd25b",
  storageBucket: "hare-bd25b.appspot.com",
  messagingSenderId: "71572258277",
  appId: "1:71572258277:web:4af079a02e4d4b439d830c",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
