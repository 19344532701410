import React from "react";
import Modal from "./Modal";

const Download = ({ open, onClose }) => {
  React.useEffect(() => {
    document.title = "HARE - Please wait";
    return () => {
      document.title = "HARE - We listen & fix IT";
    };
  }, []);
  return (
    <Modal open={open}>
      <div className="p-10 mx-auto text-right rounded ">
        {/* Loader */}
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
        {/* <p className="text-white">Please wait</p> */}
        {/* <div className="py-10">
          <button type="button" className="btn primary" onClick={onClose}>
            cancel
          </button>
        </div> */}
      </div>
    </Modal>
  );
};

export default Download;
