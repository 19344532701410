import {
  useState,
  useRef,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import { ChevronDoubleDownIcon } from "@heroicons/react/24/solid";
import {
  ChatBubbleOvalLeftEllipsisIcon,
  CheckCircleIcon,
  LightBulbIcon,
} from "@heroicons/react/24/outline";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import ScreenShotsModal from "../components/ScreenShotsModal";
import logo from "../assets/logo/full-logo.png";
import hmj from "../assets/logo/hmj.png";
import motherboard from "../assets/motherboard.mp4";
import Terms from "../components/Terms";
import Download from "../components/Download";
import { app } from "../firebase";

const storage = getStorage(app);

function Home() {
  const [showScreenShots, setShowScreenShots] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [agreed, setAgreed] = useState(false);

  const videoElm = useRef(null);

  const download = useCallback(() => {
    const pathReference = ref(storage, "hare.zip");
    getDownloadURL(pathReference)
      .then((url) => {
        const ancorEl = document.createElement("a");
        ancorEl.href = url;

        // Update db before download
        const dbURL = "http://localhost:3000/api/customer/hare/download";
        // const dbURL =
        //   "https://hamjambo-online-store.vercel.app/api/customer/hare/download";
        fetch(dbURL, {
          method: "post",
        });
        ancorEl.click();
      })
      .catch((error) => {
        alert(error.message);
      })
      .finally(() => {
        setShowDownload(false);
      });
  }, []);

  const inCrementPageVisit = useCallback(() => {
    const dbURL = "http://localhost:3000/api/customer/hare/visit";
    // const dbURL =
    //   "https://hamjambo-online-store.vercel.app/api/customer/hare/visit";
    fetch(dbURL, {
      method: "post",
    });
  }, []);

  useEffect(() => {
    if (showDownload) {
      download();
    }
  }, [download, showDownload]);

  useEffect(() => {
    if (videoElm.current) {
      videoElm.current.crossOrigin = "anonymous";
      videoElm.current.muted = true;
      videoElm.current.playbackRate = 0.5;
      videoElm.current.play();
    }
  }, []);

  // update page visit count
  const visited = useRef(false);
  useLayoutEffect(() => {
    if (!visited.current) {
      inCrementPageVisit();
      visited.current = true;
    }
  }, [inCrementPageVisit]);
  return (
    <>
      {showScreenShots && (
        <ScreenShotsModal
          open={showScreenShots}
          onClose={() => setShowScreenShots(false)}
        />
      )}
      {showTermsAndConditions && (
        <Terms
          open={showTermsAndConditions}
          onClose={() => setShowTermsAndConditions(false)}
        />
      )}
      {showDownload && (
        <Download open={showDownload} onClose={() => setShowDownload(false)} />
      )}

      {/* Chat */}
      <a
        href="https://wa.me/254715061780"
        className="fixed z-50 p-4 bg-teal-500 rounded-full shadow-md hover:scale-95 bottom-2 right-2 lg:bottom-4 lg:right-4"
      >
        <ChatBubbleOvalLeftEllipsisIcon className="w-6 h-6 text-white" />
      </a>

      {/* Landing */}
      <section className="relative min-h-screen bg-gray-800">
        <img
          src="https://cdn.pixabay.com/photo/2015/08/11/20/52/motherboard-885177_960_720.jpg"
          className="absolute block object-cover w-full h-full opacity-20"
          alt=""
        />
        {/* <video
            ref={videoElm}
            src={motherboard}
            className="absolute block object-cover w-full h-full opacity-10"
          ></video> */}

        {/* <div className="absolute inset-0 z-10 bg-gray-900/80" /> */}
        {/* <img src={hmj} alt="" className="absolute z-50 rotate-45 top-16 right-8" /> */}
        <div className="relative z-20 flex flex-col items-center justify-end min-h-screen p-4 text-center md:p-10">
          <div className="py-4">
            <img
              src={logo}
              alt="icon"
              className="object-contain w-[150px]  md:w-[250px]"
            />
          </div>
          <h1 className="text-white text-4xl md:text-5xl md:text-[5vw] py-2 md:py-4 tracking-tighter font-semibold">
            Hare System Scanner
          </h1>
          <h2 className="text-white/60 text-xl md:text-[1.5vw] py-5 tracking-tight">
            Available for Windows Machines
          </h2>
          <button
            className="btn accent"
            onClick={() => setShowScreenShots(true)}
          >
            View Screenshots
          </button>
          <div className="mt-24 md:mt-40">
            <div className="flex flex-row items-center mb-2 space-x-2">
              <input
                type="checkbox"
                className=""
                onChange={(e) => {
                  if (e.target.checked) {
                    setAgreed(true);
                  } else {
                    setAgreed(false);
                  }
                }}
              />
              <h6 className="text-sm font-medium text-gray-500">
                I agree to the{" "}
                <button
                  type="button"
                  className="text-orange-400 hover:underline"
                  onClick={() => setShowTermsAndConditions(true)}
                >
                  Terms and conditions
                </button>
              </h6>
            </div>
            <button
              className="btn primary disabled:cursor-not-allowed disabled:hover:bg-sky-300 disabled:bg-sky-300 disabled:text-sky-200"
              disabled={!agreed}
              onClick={() => setShowDownload(true)}
            >
              Download Now
            </button>
            <h1 className="md:text-[1vw] text-white/60 my-6 ">
              Read more below
            </h1>
            <ChevronDoubleDownIcon className="inline-block w-10 h-10 text-white animate-bounce" />
          </div>
        </div>
      </section>

      {/* Overview */}
      <section className="max-w-screen-xl px-4 py-10 mx-auto text-center md:py-32">
        <h1 className="text-3xl md:text-5xl ">Overview</h1>
        <p className="md:text-xl">
          Does your PC feel like it needs a boost? We believe that identifying
          the problem is the first step toward fixing it. Most people are not
          able to see and share information on the health of their PC. We rely
          on the technician's word.
        </p>
        <p className="mt-4 md:text-xl">
          HARE listens and checks your computer's Memory, Storage, Motherboard,
          CPU and Battery. Think of this as having your vitals checked by the
          nurse in the triage before seeing the doctor; i.e. your temperature,
          blood pressure, weight, blood oxygen levels
        </p>
      </section>

      {/* More about the app */}
      <section className=" bg-gray-700  text-center md:text-left relative min-h-[50vh]">
        <img
          src="https://images.unsplash.com/photo-1641507924463-fa2e517146f1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1633&q=80"
          alt=""
          className="absolute object-cover w-full h-full opacity-20"
        />
        <div className="relative z-20 grid max-w-screen-xl grid-cols-1 gap-4 px-4 py-10 mx-auto lg:grid-cols-2 md:gap-16 md:py-16">
          <div>
            <div className="md:flex md:space-x-6">
              <div className="p-4">
                <LightBulbIcon className="inline-block w-16 h-16 text-sky-600" />
              </div>
              <div>
                <h1 className="text-xl md:text-2xl">How HARE works</h1>
                <p className="text-white ">
                  HARE gathers system intel from the machine and provides
                  information for high quality fixes thus ensuring optimum
                  performance. Our repair engine offers clarity on hardware and
                  software issues in an unsurpassed, clear, step by step manner
                  thus offers solutions specific to the problem in hand. <br />
                  <a
                    href="https://wa.me/254715061780"
                    className="text-yellow-500 hover:underline"
                  >
                    Chat with us
                  </a>{" "}
                  if you cannot use the app.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="md:flex md:space-x-6">
              <div className="p-4">
                <CheckCircleIcon className="inline-block w-16 h-16 text-sky-600" />
              </div>
              <div>
                <h1 className="text-xl md:text-2xl">Our guarantee</h1>
                <p className="text-white ">
                  We cannot access any of your personal data, so using it will
                  not compromise your system security. If you are concerned
                  about downloading this file, you can scan it with your
                  antivirus software before running it.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Download instructions */}
      <section className="max-w-screen-lg px-4 py-10 mx-auto md:py-16">
        <h1 className="text-xl md:text-2xl">
          Downloading and running the Hare System Scanner is easy.
        </h1>
        <ol className="flex flex-col px-8 list-decimal md:text-lg">
          <li>
            Click the &quot;I agree to the terms and conditions&quot; box in
            order to proceed with scan.
          </li>
          <li>
            Click &quot;Scan my Computer&quot; and choose &quot;Save File&quot;
            when prompted.
          </li>
          <li>Save the file to your desktop, or wherever you prefer.</li>
          <li>
            Navigate to the hare.exe file and open it. If you get a security
            warning, select &quot;Run&quot; to allow the download.
          </li>
          <li>
            It may take several moments for your browser to update with your
            scan results.
          </li>
        </ol>
        <h1 className="mt-8 text-xl md:text-2xl">
          Removing the Scanner is easy
        </h1>
        <p className="md:text-lg">
          Just delete the hare.exe file that you downloaded. The scanner does
          not install anything on your system.
        </p>
      </section>

      {/* About company */}
      <section className="max-w-screen-lg px-4 py-10 mx-auto text-center border-t-4 md:py-16">
        <h1 className="text-3xl md:text-5xl ">About us</h1>
        <p className="text-sm text-gray-600 md:text-base ">
          <b>HARE</b> is a subsidiary of{" "}
          <a
            href="https://hamjambo.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-yellow-500"
          >
            hamjambo.com
          </a>
          . Hamjambo provides home and business technology products, with round
          the clock support.
          <br />
          <br />
          We provide comprehensive technology solutions for business.
          <br />
          <br />
          Our enterprise solutions include structured cabling, networking,
          security, access, and surveillance solutions. With our expertise and
          commitment to quality, we ensure seamless connectivity and protection
          for your business.
        </p>
      </section>

      {/* Footer */}
      <div className="py-8 bg-gray-200 ">
        <div className="max-w-screen-lg px-4 mx-auto text-center">
          <p className="text-sm ">
            {/* <span className="font-bold text-sky-500">HARE</span> */}
            Powered by{" "}
            <a
              href="https://hamjambo.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={hmj}
                alt=""
                className="inline-block object-contain w-24"
              />
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

export default Home;
