import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  const isRouterError = isRouteErrorResponse(error);

  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center h-screen text-center text-white bg-[#1A222E]">
      <h1 className="text-xl font-bold">
        {isRouterError && error.status === 404 ? "404" : "Opps"}
      </h1>
      <p>
        {isRouterError && error.status === 404
          ? "Page not found"
          : "Sorry, an unexpected error has occurred." + error.message}
      </p>
    </div>
  );
}
